export const DOCTOR_FUNCTIONS = {
  logout: '1eba6ad0-3df7-62d2-976d-2cf05d694255',
  getDataDoctor: '1eba6ad0-3e08-6208-867e-2cf05d694255',
  updateDoctorData: '1eba6ad0-3e09-6504-8c2a-2cf05d694255',
  //getFunctions:
  getMedicalSampleMedicines: '1ebb999f-73f5-61d2-a920-2cf05d694255',
  getTherapeuticClassesMedicalSample: '1ebb9b2f-1d5e-6990-bf6b-2cf05d694255',
  markFavoriteMedicine: '1ebb274b-06e9-6da2-803a-2cf05d694255',
  getMSMedicinesByLaboratories: '1ebbcc99-6210-630e-986e-2cf05d694255',
  getMedicineById: '1eba6ad0-3e0a-6b98-b4c8-2cf05d694255',
  getNotificationsByDoctor: '1ebc49e1-e748-6e8e-96d1-2cf05d694255',
  getNotificationByDoctor: '1ebc49e1-e73b-6eaa-92a8-2cf05d694255',
  deleteDoctorNotification: '1ebc49e1-e749-623a-a31c-2cf05d694255',
  getOrdersHistory: '1ebbda69-9acd-6772-bd66-2cf05d694255',
  getMedicalSampleById: '1ebb4de7-9dbf-67a0-84e4-2cf05d694255',
  getInitialTreatmentById: '1ebadfd9-6b18-6134-81cc-2cf05d694255',
  getPricesByMedicine: '1ebc5915-86a6-61a6-9325-2cf05d694255',
  sendPhoneNumberCode: '1ebc7d86-365b-6e68-83aa-2cf05d694255',
  validatePhoneNumberCode: '1ebc7d86-3667-69d4-a696-2cf05d694255',
  createMedicalSample: '1ebb4de7-9db3-6860-997b-2cf05d694255',
  getTherapeuticClassesByDoctor: '1ebb82ec-b7e2-671a-806f-2cf05d694255',
  getInitialTreatmentMedicines: '1ebb4de7-9dc0-602e-b5f1-2cf05d694255',
  createInitialTreatmentsOrder: '1ebadfd9-6b0c-6faa-a44f-2cf05d694255'
}

export const DISTRIBUTION_TYPES = {
  initialTreatment: '1eba2d2b-5de6-6332-a2d4-2cf05d694255',
  medicalSample: '1eba2d2c-0d87-6b88-add3-2cf05d694255'
}

export const INITIAL_TREATMENT_STATUSES = {
  treatmentStarted: '1ebac56e-23fb-6768-9926-2cf05d694255',
  acceptedByPatient: '1ebac56e-2427-6cc8-afc3-2cf05d694255',
  noAcceptedByPatient: '1ebac56e-2427-6f84-90d5-2cf05d694255',
  orderRealized: '1ebac56e-2428-6128-8450-2cf05d694255',
  notOrderForDifficulty: '1ebac56e-2428-629a-be3c-2cf05d694255',
  receivedByPatient: '1ebac56e-2428-6466-93f5-2cf05d694255',
  expired: '1ec26d63-7beb-68a2-bd3c-2cf05d694255'
}

export const MEDICAL_SAMPLE_STATUSES = {
  sent: '1ebb3fe9-712d-65f4-b61f-2cf05d694255',
  attended: '1ebb3fe9-714e-6a60-9e70-2cf05d694255',
  expired: '1ebb3fe9-714e-6d30-895c-2cf05d694255'
}

export const CODE_OPTIONS = {
  EMAIL: 'email',
  PHONE: 'phoneNumber'
}

export const CouponTypes = {
  DummyCoupon: '1ec96670-adc4-6ec0-bd4a-2cf05d694255',
  SimpleCoupon: '1ec966a5-6a06-6e2e-9360-2cf05d694255',
  PspCoupon: '1ec966a5-f1da-60b2-b551-2cf05d694255',
  StudyCoupon: '1ed6c519-0f14-67c0-b7f4-92f70d155d1d',
}

let env = 'qa' // local | qa | prod
export const dotNetUrlApi =
  env === 'local' ? 'http://localhost:8081' :
  env === 'qa'    ? 'https://iniciotxdevelop.azurewebsites.net/api' :
  env === 'prod'  ? 'https://iniciotxservice.azurewebsites.net/api' :
  ''
