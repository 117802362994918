import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private messageService: MessageService) {
  }

  public showMessage(severity: string, summary: string, message: string) {
    this.messageService.add({
      key: 'app-main-toast',
      severity,
      summary,
      detail: message || 'Algo salió mal. Inténtalo más tarde.',
      closable: false
    });
  }

}
