import {environment} from '../environments/environment';

export class AppSettings {

  //Prefix for LocalStorage
  public static get localStoragePrefix(): string {
    return 'itx';
  }

  //Prefix for authentication LocalStorage
  public static get authTokenLocalStorageKey(): string {
    return `${this.localStoragePrefix}-auth`;
  }

  //Host for user services
  public static get userHost(): string {
    return `${environment.api.host}/user`;
  }

   //Host for user services IKS
   public static get userHostIKS(): string {
    return `${environment.api.IKShost}/user`;
  }

  // Host pfor order services
  public static get orderHost(): string {
    return `${environment.api.host}/order`;
  }

  // Host for notification services
  public static get notificationHost(): string {
    return `${environment.api.host}/notification`;
  }

  // Host for laboratory services
  public static get laboratoryHost(): string {
    return `${environment.api.host}/laboratory`;
  }

  //Prefix for permissions
  public static get itxPermissionPrefix(): string {
    return `${this.localStoragePrefix}-itx-permission`;
  }

  public static get mmPermissionPrefix(): string {
    return `${this.localStoragePrefix}-mm-permission`;
  }

  public static get tutorialPrefix(): string {
    return `${this.localStoragePrefix}-tutorial`;
  }

}
